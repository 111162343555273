import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import { useCallback, useState } from "react";
import playIcon from "../images/play.png";
import StreamModal from "./StreamModal";
import useWidth from "../hooks/useWidth";
import useVideoStreamingList from "../hooks/useVideoStreamingList";
import Loading from "./Loading";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export default function VideoStreaming({ videoStreamingData }) {
  const [streamModal, setStreamModal] = useState(false);
  const closeStreamModal = useCallback(() => setStreamModal(false), []);
  const [play, setPlay] = useState(false);

  const playVideo = (e) => {
    setPlay(e.currentTarget.id);
  };

  const [streamingVideos, setStreamingVideos] = useState();

  const handleStreamModal = (index) => {
    const demoVideos = videoStreamingData.map((element) => element);
    const prevVideos = demoVideos.splice(0, index);
    setStreamingVideos(demoVideos.concat(...prevVideos));
    setStreamModal(true);
    setPlay(false);
  };

  const width = useWidth();

  return (
    <div className="px-2 offset-md-2  col-md-9 mx-auto">
      {videoStreamingData.length > 0 && (
        <>
          <div className="my-3 ">
            {width >= 768 ? (
              <h2>BD Beauty Glamorous STREAMING</h2>
            ) : (
              <h5 className="text-center">BD Beauty Glamorous STREAMING</h5>
            )}
          </div>
          {streamModal && (
            <StreamModal videos={streamingVideos} close={closeStreamModal} />
          )}
          <div className="ready-video-for-streaming">
            <Carousel
              responsive={responsive}
              autoPlaySpeed={3000}
              infinite={true}
              autoPlay={true}
              arrows={true}
            >
              {videoStreamingData.map((video, index) => (
                <div
                  className="position-relative d-inline-block cursor-pointer streaming-video-describe"
                  key={video.id}
                  onClick={() => handleStreamModal(index)}
                >
                  <div className="video-object-cover">
                    <ReactPlayer
                      width={240}
                      height={400}
                      url={video.path}
                      playing={play === video.id.toString()}
                      muted={true}
                    />
                  </div>
                  {play !== video.id.toString() && (
                    <div
                      id={video.id}
                      className="position-absolute w-100 h-100"
                      style={{ top: 0, left: 0 }}
                      onMouseEnter={playVideo}
                    >
                      <div className="w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
                        <button
                          type="submit"
                          className="position-absolute bg-transparent border-0"
                          style={{ zIndex: 1 }}
                        >
                          <img
                            src={playIcon}
                            width={50}
                            height={50}
                            alt="Play Video"
                          />
                        </button>
                      </div>
                      <div className="w-100 h-50 position-absolute streaming-video-title-contain">
                        <div
                          style={{ padding: "7%" }}
                          className="d-flex align-items-end h-100"
                        >
                          <h5 className="streaming-video-title">
                            {video.title}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </div>
  );
}
