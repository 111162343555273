import MBBestSellerBackground from '../images/MBBestSellerBackground.webp'
import { Link } from 'react-router-dom'
import shadesIcon from '../images/shades.png'
import MBSectionCard from './MBSectionCard'

export default function MBSection({ datam, headline, descrive }) {
	return (
		<div className="text-center mb-4">
			<div>
				<div className="">
					<div className="my-3">
						<h5>{headline}</h5>
					</div>
					<div>
						<div style={{ overflow: 'hidden' }}>
							<div className="lay7Box">
								<div
									className={`stack-top box ${descrive ? 'py-2' : ''} auto-carousel-x`}
									style={{
										display: 'flex',
										...(descrive && {
											backgroundImage: `url("${MBBestSellerBackground}")`,
										}),
									}}
								>
									{descrive && (
										<div className="px-3 d-inline-block">
											<div>
												<p
													className="text-center py-5 px-2 mt-5 discount-off-percentage "
													style={{ width: '140px' }}
												>
													{descrive}
												</p>
											</div>
										</div>
									)}
									{datam.map(data => (
										<MBSectionCard data={data.product ? data.product : data} key={data.id} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
