import FooterHeading from '../components/FooterHeading'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MBNavbar from '../components/MBNavbar'
import MBFooter from '../components/MBFooter'
import useWidth from '../hooks/useWidth'
import common from '../common.json'
import LoadingSpinner from '../components/LoadingSpinner'
import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";

export default function PageContent({name, title}) {
	const width = useWidth();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [pageContent, setPageContent] = useState({});

	useEffect(() => {
		async function fetchOrders() {
			try {
				await axios
					.post("page-content", {
						slug : name
					})
					.then((response) => {
						setPageContent(response.data.data);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setPageContent({});
						setLoading(false);
						setError(err);
					});
			} catch (error) {
				setLoading(false);
				setError("Connection Error");
			}
		}

		fetchOrders();

		return () => {
			setPageContent({});
			setError("");
			setLoading(true);
		};
	}, [name]);


	return (
		<>
			{width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}
			<FooterHeading title={title} />
			<section className="footer-page-body px-0 px-md-5 mt-5 pb-5 pb-md-0">
				{!loading && Object.keys(pageContent).length === 0 && (<div> No Data found</div>)}

				{loading && !error && (<div><LoadingSpinner /></div>)}

				{loading && error && (<div> There is an error</div>)}
				
				{ pageContent && (
					<>
						<div dangerouslySetInnerHTML={{__html: pageContent.detail}} />
					</>
				)}
			</section>
			{width >= 768 ? <Footer /> : <MBFooter />}
		</>
	)
}
