import { MdLocalMall, MdFavorite, MdClose } from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo1x1.jpg";
import SearchPalette from "./SearchPalette";
import persionIcon from "../images/person.png";
import discountIcon from "../images/discountIcon.svg";
import { useWebsite } from "../context/WebsiteContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isLoggedInCheck, callToast } from "../lib/functions";
import axios from "../lib/axiosConfig";
import useUser from "../hooks/useUser";

export default function NavSearchbar({ handler }) {
  let navigate = useNavigate();
  const { logoutUser } = useUser();
  const {
    websiteInfo,
    wishlistCount,
    isLoggedInState,
    setIsLoggedInState,
    totalCartQuantity,
    setTotalCartQuantity,
  } = useWebsite();

  const [searchPalette, setSearchPalette] = useState(false);
  const [search, setSearch] = useState("");
  const changeSearch = (e) => setSearch(e.currentTarget.value);

  const searchProduct = () => {
    navigate({ pathname: "/search", replace: true, search: `?q=${search}` });
  };

  const handleKeyUp = (e) => {
    if (e.which === 13) {
      searchProduct();
    }
  };

  return (
    <div className="d-flex bg-light py-3">
      <div className="col-md-9 mx-auto">
        <div className="row justify-content-between">
          <div className="col-3 d-flex">
            <Link to="/" style={{ color: "black" }}>
              <div className="py-0">
                <img
                  src={websiteInfo.photo_path}
                  title="BD Beauty Glamorous"
                  alt="BD Beauty Glamorous"
                  style={{
                    position: "absolute",
                    marginTop: "-10px",
                    width: "150px",
                    height: "60px",
                  }}
                />
              </div>
            </Link>
          </div>
          <div className="col-4 d-flex justify-content-between">
            <div
              className="col-12 rounded-pill d-flex bg-white navbarDropdown"
              style={{ height: "40px" }}
              onMouseLeave={() => setSearchPalette(false)}
            >
              <input
                className="col-9 px-4 searchInput"
                placeholder='Try "Liquid Lipstick"'
                value={search}
                onMouseEnter={() => setSearchPalette(true)}
                onChange={changeSearch}
                onKeyUp={(e) => handleKeyUp(e)}
              />
              <div
                className="col-1 d-flex justify-content-center pt-2 text-muted cursor-pointer"
                style={{ zIndex: 100, backgroundColor: "white" }}
                onClick={() => setSearch("")}
              >
                {search !== "" && <MdClose fontSize={22} />}
              </div>
              <div
                className="col-3 bg-dark d-flex justify-content-center py-2 searchButton cursor-pointer"
                style={{
                  color: "white",
                  fontSize: "15px",
                }}
                onClick={() => searchProduct()}
              >
                Search
              </div>
              {searchPalette && <SearchPalette id={11} />}
            </div>
          </div>
          <div
            className="col-3 d-flex justify-content-center"
            style={{ fontSize: "15px" }}
          >
            <div className="d-flex justify-content-center">
              <div className="py-2">
                <img src={persionIcon} className="" alt="" />
              </div>
              {isLoggedInState === true ? (
                <>
                  <div
                    className="px-1 py-2 text-decoration-underline cursor-pointer"
                    onClick={logoutUser}
                  >
                    Logout
                  </div>
                  <Link
                    to="/profile"
                    className="px-1 py-2 text-decoration-underline cursor-pointer"
                  >
                    Profile
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="px-1 py-2 text-decoration-underline cursor-pointer"
                  >
                    Login
                  </Link>
                  <div
                    className="px-1 py-2 text-decoration-underline cursor-pointer"
                    onClick={handler}
                  >
                    Register
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-2 d-flex justify-content-start  py-1">
            <div className="px-2 position-relative">
              <Link to="/wishlist" className="text-decoration-none color-black">
                <MdFavorite color="#495057" fontSize="20px" />
                {wishlistCount && wishlistCount > 0 ? (
                  <span className="position-absolute text-center productCartCount collectionOfferPosition">
                    <span className="px-1">{wishlistCount}</span>
                  </span>
                ) : (
                  ""
                )}
              </Link>
            </div>
            <div className="px-1">
              <Link to="/cart" className="text-decoration-none color-black">
                <div className="position-relative">
                  <MdLocalMall color="#495057" fontSize="20px" />
                  {totalCartQuantity && totalCartQuantity > 0 ? (
                    <span className="position-absolute text-center productCartCount collectionOfferPosition">
                      <span className="px-1">{totalCartQuantity}</span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </div>
            <Link to="/offers">
              <div className="px-2 cursor">
                <img
                  src={discountIcon}
                  alt="discount"
                  width="18px"
                  height="18px"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
