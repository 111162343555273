import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import {callToast, getValueLocalStorage} from "../lib/functions";
import {useNavigate} from 'react-router-dom'
import { useWebsite } from '../context/WebsiteContext'

const useUser = () => {
	const navigate = useNavigate();
	const { websiteInfo, wishlistCount, isLoggedInState,setIsLoggedInState, setWishlistCount, totalCartQuantity, setTotalCartQuantity } = useWebsite();

	const logoutUser = () => {
		var loggedToken = getValueLocalStorage('loggedToken');

		axios({
			method: "post",
			url: "logout-user",
			headers: { Authorization: `Bearer ${loggedToken}` }
		})
		.then((response) => {
			callToast('success','Your successfully Logout'); 

			localStorage.setItem("isLoggedIn", false);
			localStorage.setItem("loggedToken", '');
			setIsLoggedInState(false);
			setTotalCartQuantity(null);
			setWishlistCount(null);
			return navigate("/");
		})
		.catch((err) => {
			callToast('error','Something is missing'); 
			console.log(err);
		});
	}; 


	return {
		logoutUser
	};
};

export default useUser;
