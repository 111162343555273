import image from '../images/image1x1.jpg'
import image1 from '../images/image1x1-1.jpg'
import Offerblog from '../components/OfferBlog'
import { useState,useEffect } from 'react'
import Footer from '../components/Footer'
import MBFooter from '../components/MBFooter'
import useWidth from '../hooks/useWidth'
import BackNavbar from '../components/BackNavbar'
import Navbar from '../components/Navbar'
import axios from "../lib/axiosConfig";
import {isLoggedInCheck, callToast,setValueLocalStorage,getValueLocalStorage} from "../lib/functions"
import { useWebsite } from '../context/WebsiteContext'
import MBNavbar from '../components/MBNavbar'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
	ErrorMessage,
	FastField,
	Field,
	FieldArray,
	Form,
	Formik,
} from "formik";
import * as Yup from "yup";

const initialValues = {
	email: "",
	password: "",
};





export default function Login() {
	const {setIsLoggedInState, setUserInfo, setTotalCartQuantity, setTotalCartAmount, cartDispatch, setWishlist, setWishlistCount} = useWebsite();
	const width = useWidth();
	let navigate = useNavigate();
	const [emailCustomError, setEmailCustomError] = useState('');
	const [passwordCustomError, setPasswordCustomError] = useState('');
	
	 
   
	const loadUser = async () => {
		var loggedToken = getValueLocalStorage(); 
		await axios({
			method: "post",
			url: "user",
			headers: { Authorization: `Bearer ${loggedToken}` }
		})
		.then((response) => {
			setIsLoggedInState(true);
			setUserInfo(response.data.user); 
			setTotalCartQuantity(response.data.cart.cartProductQuantity);
			setTotalCartAmount(response.data.cart.cartProductAmount);
			setWishlist(response.data.wishlist.wishlistProducts);
			setWishlistCount(response.data.wishlist.wishlistCount);
			
			cartDispatch({
				type: "SET_CART",
				payload: response.data.cart.cartProduct,
			});
			

			return navigate("/cart");
		})
		.catch((err) => {
			setIsLoggedInState(false);
			callToast('warn', "Wow so easy!")
		});
	};

 
	useEffect(() => {
		if(isLoggedInCheck()){
			loadUser();
		}
	}, []);


	const validationSchema = Yup.object({
		email: Yup.string().email("Invalid email format").required("Required Email Address!"),
		password: Yup.string().required("Required Name !"), 
	});




	const onSubmit = async (values, onSubmitProps) => {

		await axios({
			method: "post",
			url: "login-user",
			data: values,
		})
			.then((response) => {
				setIsLoggedInState(true);
				setUserInfo(response.data.user);
				callToast('success','Your successfully login'); 

				localStorage.setItem("isLoggedIn", true);
				if(response.data && response.data.token){
					setValueLocalStorage(response.data.token);
				}
				setWishlist(response.data.wishlist.wishlistProducts);
				setWishlistCount(response.data.wishlist.wishlistCount);
				
				setTotalCartQuantity(response.data.cart.cartProductQuantity);
				setTotalCartAmount(response.data.cart.cartProductAmount);
				
				cartDispatch({
					type: "SET_CART",
					payload: response.data.cart.cartProducts,
				});

				return navigate("/cart");
			})
			.catch((err) => {
				callToast('error','Invalid Credential'); 

				setIsLoggedInState(false);

				localStorage.setItem("isLoggedIn", false);
				localStorage.setItem("loggedToken", '');
				setEmailCustomError('');
				setPasswordCustomError('');
				
				if(err.response && err.response.data.errors){
					err.response.data.errors.forEach((item, index)=>{ 
						if(item.code === "email"){
							setEmailCustomError( item.message);
						}
						if(item.code === "password"){
							setPasswordCustomError( item.message);
						} 
					}) 
				} 
			});
	}; 


	return (
		<>
			{width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}

			<div className="shadow-lg mt-4 mx-3 bg-white" style={{ borderRadius: '20px' }}>
				<div className="row pt-2 container-fluid">
					<div className="offset-md-3 col-md-6 col-lg-4 my-2" >
						<section id="section-basic-example">
							<h2 className="mb-4">This Login Page</h2>
							<section className="pb-4">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
									validateOnChange={false}
									validateOnBlur={false}
								>
									{(formik) => {
										return (
											<Form> 
												<div className="mb-3">
													<label htmlFor="email">Email</label>
													<Field type="text" id="email" name="email" className="form-control" />
													<ErrorMessage name="email">
														{(msg) => <div className="error">{msg}</div>}
													</ErrorMessage>
													{ emailCustomError !== '' && <div className="error">{ emailCustomError }</div>}
												</div>
												<div className="mb-3">
													<label htmlFor="password">password</label>
													<Field type="password" id="password" name="password" className="form-control" />
													<ErrorMessage name="password">
														{(msg) => <div className="error">{msg}</div>}
													</ErrorMessage>
													{ passwordCustomError !== '' && <div className="error">{ passwordCustomError }</div>}
												</div> 
												<div className="d-grid">
													<button type="submit" className="btn btn-primary">
														Submit
													</button> 
												</div>
											</Form>
										);
									}}
								</Formik>
							</section>
							{(width <= 768) && (
								<div className='text-center mt-5'>
									<Link to={`/registration`}>
										Registration
									</Link>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>

			{width >= 768 ? <Footer /> : <MBFooter />}
		</>
	)
}
