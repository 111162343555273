import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import {callToast} from "../lib/functions";
import {useNavigate} from 'react-router-dom'
import { useWebsite } from '../context/WebsiteContext'
import {getValueLocalStorage} from '../lib/functions'

const useAddProduct = () => {
	const navigate = useNavigate();
	const {cartDispatch, isLoggedInState, setTotalCartQuantity, setTotalCartAmount, setWishlist, setWishlistCount} = useWebsite();

	const handleProductIntoCart = async (product, selectedColor=null) => {
		if(isLoggedInState === true){
			var loggedToken = getValueLocalStorage('loggedToken');
			try {
				await axios({
					method: 'post',
					url: 'add-to-cart',
					headers: { Authorization: `Bearer ${loggedToken}` },
					data: {
						product_id : product.id,
						quantity  : 1,
						sku_id :  (selectedColor) ? selectedColor.id : null
					}
				})
				.then((response) => {
					callToast('success',response.data.message); 
					cartDispatch({
						type: "SET_CART",
						payload: response.data.cart.cartProducts,
					});
					setTotalCartQuantity(response.data.cart.cartProductQuantity);
					setTotalCartAmount(response.data.cart.cartProductAmount);
				})
				.catch((err) => {
					callToast('warn','Product Add into Error'); 
				});
			} catch (error) {
				callToast('warn','Product Add into Error');	
			}
		}
		else{
			callToast('warn',"Please Login"); 
			return navigate('/login');
		}
	} 

	const handleProductIntoWishlist = async (product, selectedColor=null) => {

		if(isLoggedInState === true){
			var loggedToken = localStorage.getItem('loggedToken');
			try {
				await axios({
					method: 'post',
					url: 'add-to-wishlist',
					headers: { Authorization: `Bearer ${loggedToken}` },
					data: {
						product_id : product.id,
						quantity  : 1,
						sku_id : (selectedColor) ? selectedColor.id : null
					}
				})
				.then((response) => {
					callToast('success',response.data.message);  
					setWishlist(response.data.wishlist.wishlistProducts);
					setWishlistCount(response.data.wishlist.wishlistCount);
				})
				.catch((err) => {
					callToast('warn','Product Add into Error'); 
				});
			} catch (error) {
				callToast('warn','Product Add into Error');	
			}
		}
		else{
			callToast('warn',"Please Login"); 
			return navigate('/login');
		}
	} 


	return {
		handleProductIntoCart,
		handleProductIntoWishlist
	};
};

export default useAddProduct;
