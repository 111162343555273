import Bereadcrumb from "../components/Bereadcrumb";
import { useState, useEffect} from "react";
import withCart from "../HOCs/withCart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link, useNavigate } from "react-router-dom";
import { callToast,getValueLocalStorage } from "../lib/functions"


import axios from "../lib/axiosConfig";
import {
	ErrorMessage,
	FastField,
	Field,
	FieldArray,
	Form,
	Formik,
} from "formik";
import * as Yup from "yup";

 
function ProfileUpdate() {
	const { userInfo, setUserInfo} = useWebsite();
	const navigate = useNavigate();


	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		contactNumber: '',
		address: '',
	});

	useEffect(() => {
		setInitialValues({
			name: userInfo.name,
			email: userInfo.email,
			contactNumber: userInfo.contact_number,
			address: userInfo.address
		})
	}, [userInfo])
	


	const [nameCustomError, setNameCustomError] = useState("");
	const [emailCustomError, setEmailCustomError] = useState("");
	const [addressCustomError, setAddressCustomError] = useState("");
	const [contactNumberCustomError, setContactNumberCustomError] = useState("");

	const validationSchema = Yup.object({
		name: Yup.string().required("Required Name !").nullable(),
		contactNumber: Yup.string().required("Required Contact Number!").nullable(),
		email: Yup.string()
		.email("Invalid email format")
		.required("Required Email Address!").nullable(),
		address: Yup.string().required("Required Address!").nullable(),
	});

	const onSubmit = async (values, onSubmitProps) => {
		var loggedToken = getValueLocalStorage(); 
		await axios({
			method: "post",
			url: "profile-update",
			headers: { Authorization: `Bearer ${loggedToken}` },
			data: values,
		})
		.then((response) => {  
			setUserInfo(response.data.user); 
			callToast('success',response.data.message); 
			return navigate("/profile"); 
		})
		.catch((err) => {
			setNameCustomError("");
			setEmailCustomError("");
			setContactNumberCustomError("");
			setAddressCustomError("");
			if (err && err.response && err.response.data.errors) {
				err.response.data.errors.forEach((item, index) => {
					if (item.code === "name") {
						setNameCustomError(item.message);
					}
					if (item.code === "contactNumber") {
						setContactNumberCustomError(item.message);
					}
					if (item.code === "email") {
						setEmailCustomError(item.message);
					} 
					if (item.code === "address") {
						setAddressCustomError(item.message);
					} 
				});
			} 
		});
	};

	return (
		<>
			<Navbar />
			<div style={{ overflowX: "hidden" }} className="pb-3">
				<div>
					<Bereadcrumb pages={[{ path: "/cart", name: "Cart" }]} />
					<div
						className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
						style={{ borderRadius: "20px" }}
					>
						<div className="row container-fluid">
							<div className="col-12 col-lg-4 px-4 ">
								<section className="mt-3">
									<CustomerSideBar />
								</section>
							</div>
							<div className="col-12 col-lg-8 py-3">
								<section>
									<div className="d-flex justify-content-between cart_orderSummary">
										<div>
											<span>
												<h6>
													<span className="px-2">
														Update Profile
													</span>
												</h6>
											</span>
										</div>
									</div>
								</section>
								<div
									style={{
										background: "#faf9f9",
										borderRadius: "10px",
									}}
									className="p-2 "
								> 
									
									{userInfo && (
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={onSubmit}
										validateOnChange={false}
										validateOnBlur={false}
										enableReinitialize={true}
									>
										{(formik) => {
											return (
												<Form>
													<div className="mb-3">
														<label htmlFor="name">
															Name
														</label>
														<Field
															type="text"
															id="name"
															name="name"
															className="form-control"
														/>
														<ErrorMessage name="name">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{nameCustomError !==
															"" && (
															<div className="error">
																{
																	nameCustomError
																}
															</div>
														)}
													</div>
													<div className="mb-3">
														<label htmlFor="contactNumber">
															Contact Number
														</label>
														<Field
															type="text"
															id="contactNumber"
															name="contactNumber"
															className="form-control"
														/>
														<ErrorMessage name="contactNumber">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{contactNumberCustomError !==
															"" && (
															<div className="error">
																{
																	contactNumberCustomError
																}
															</div>
														)}
													</div>
													<div className="mb-3">
														<label htmlFor="email">
															Email
														</label>
														<Field
															type="text"
															id="email"
															name="email"
															className="form-control"
														/>
														<ErrorMessage name="email">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{emailCustomError !==
															"" && (
															<div className="error">
																{
																	emailCustomError
																}
															</div>
														)}
													</div> 
													<div className="mb-3">
														<label htmlFor="address">
															Address
														</label>
														<Field
															type="text"
															id="address"
															name="address"
															className="form-control"
														/>
														<ErrorMessage name="address">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{addressCustomError !==
															"" && (
															<div className="error">
																{
																	addressCustomError
																}
															</div>
														)}
													</div> 
													<div className="d-grid">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div>
												</Form>
											);
										}}
									</Formik>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default ProfileUpdate;
