import MBBestSellerBackground from '../images/MBBestSellerBackground.webp'
import { Link } from 'react-router-dom'
import shadesIcon from '../images/shades.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function MBSectionCard({ data }) {

	return (
		<div
			className="mx-2 rounded d-inline-block"
			style={{ backgroundColor: 'rgb(255, 255, 255)' }}
		>
			<Link to={`/product/${data.slug}`} style={{ color: 'black' }}>
				<div className="px-1">
					<div className="mainLazy position-relative">
						<span
							className="lazy-load-image-background  lazy-load-image-loaded"
							style={{ display: 'inline-block' }}
						>
							<LazyLoadImage
								src={data.image_path}
								className="cursor-pointer lazyTransition"
								alt={data.name}
								title={data.name}
								style={{ width: '130px', height: '170px' }}
							/>
						</span>
					</div>
					<div style={{ fontSize: '12px', width: '124px' }}>
						<p style={{ overflow: 'hidden' }}>
							<span className="max-tow-line">{data.name}</span>
						</p>
					</div>

					<div style={{ fontSize: '10px' }}>
						{data.discount && (
							<span className="brandColor strikeThrough">
								<span>Tk</span>
								{data.price}
							</span>
						)}
						<span className="px-1">
							<span>Tk</span>
							{data.discount ? data.product.price : data.sale_rate}
						</span>
						{data.discount && (
							<span>
								<small className="discount-off-percentage">
									({data.discount.percentage} Off)
								</small>
							</span>
						)}
					</div> 
				</div>
			</Link>
		</div>
	)
}
