import Offcanvas from 'react-bootstrap/Offcanvas'
import { MdArrowDropDown, MdClose } from 'react-icons/md'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo1x1.jpg'
import { useWebsite } from '../context/WebsiteContext'
import useUser from '../hooks/useUser'; 

export default function MBSidebar({ show, handleShow }) {

	const {logoutUser} = useUser();
	const { websiteInfo, isLoggedInState,setIsLoggedInState, totalCartQuantity, setTotalCartQuantity, categories } = useWebsite();


	const [collapse, setCollapse] = useState('') // Lavel 1
	const [collapse2, setCollapse2] = useState('') // Lavel 2
	const handleCollapse = e => {
		const name = e.currentTarget.name
		name === collapse ? setCollapse('') : setCollapse(name)
	}
	const handleCollapse2 = e => {
		const name = e.currentTarget.name
		name === collapse2 ? setCollapse('') : setCollapse2(name)
	}

	return (
		<Offcanvas show={show} onHide={handleShow} className="mb-sidebar-container">
			<Offcanvas.Body className="mb-sidebar-body">
				<div style={{ backgroundColor: 'black', height: '100%' }}>
					<div className="px-3 pt-4 d-flex justify-content-center">
						<Link to="/">
							<img src={websiteInfo.photo_path} alt={websiteInfo.name}  className="rounded-circle" height="60" />
						</Link>
					</div>
					{categories.length > 0 && categories.map((category, ci) => (
						<div key={ci}>
							<div className="px-2 my-2 pt-3">
								<div className="d-flex justify-content-between">
									<div className="flex-grow-1">
										<Link to={`/${category.slug}`} style={{ color: 'white' }}>
											<div className="mb-sidelist">{category.name}</div>
										</Link>
									</div>
									{category.sub_categories.length > 0 && (
										<button
											style={{ all: 'unset' }}
											type="button"
											name={category.name}
											onClick={handleCollapse}
										>
											{collapse === category.name ? (
												<MdClose color="red" fontSize={24} />
											) : (
												<MdArrowDropDown fontSize={24} />
											)}
										</button>
									)}
								</div>
							</div> 

							{category.sub_categories.length > 0 &&
							collapse === category.name &&
							category.sub_categories.map((sub_category, sci) => (
								<div
									className="px-3 py-2"
									style={{
										backgroundColor: 'rgb(24, 25, 26)',
									}}
									key={sci}
								>
									<div>
										<div className="d-flex justify-content-between">
											<div className="pt-3 flex-grow-1">
												<Link
													to={`/${category.slug}/${sub_category.slug}`}
													style={{
														color: 'white',
													}}
												>
													<div
														className="py-1"
														style={{
															fontWeight: 500,
															fontSize: '1.15rem',
														}}
													>
														{sub_category.name}
													</div>
												</Link>
											</div> 
										</div> 
									</div>
								</div>
							))} 
							<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
						</div>
					))}  

					<div>
						<div className="px-2 my-2 pt-3">
							<div className="d-flex justify-content-between">
								<div className="flex-grow-1">
									<Link to={`/offers`} style={{ color: 'white' }}>
										<div className="mb-sidelist">OFFERS</div>
									</Link>
								</div> 
							</div>
						</div> 
						<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
					</div>
					<div>
						<div className="px-2 my-2 pt-3">
							<div className="d-flex justify-content-between">
								<div className="flex-grow-1">
									<Link to={`/offers`} style={{ color: 'white' }}>
										<div className="mb-sidelist">MORE</div>
									</Link>
								</div> 
							</div>
						</div> 
						<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
					</div>

					{ isLoggedInState === true ? (
						
						<>
						<div>
							<div className="px-2 my-2 pt-3">
								<div className="d-flex justify-content-between">
									<div className="flex-grow-1">
										<Link to={`/profile`} style={{ color: 'white' }}>
											<div className="mb-sidelist">Profile</div>
										</Link>
									</div> 
								</div>
							</div> 
							<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
						</div>
						<div>
							<div className="px-2 my-2 pt-3">
								<div className="d-flex justify-content-between">
									<div className="flex-grow-1">
										<span style={{ color: 'white' }} onClick={logoutUser}>
											<div className="mb-sidelist">Logout</div>
										</span>
									</div> 
								</div>
							</div> 
							<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
						</div>
						</>
						) : ( <>
						<div>
							<div className="px-2 my-2 pt-3">
								<div className="d-flex justify-content-between">
									<div className="flex-grow-1">
										<Link to={`/login`} style={{ color: 'white' }}>
											<div className="mb-sidelist">Login</div>
										</Link>
									</div> 
								</div>
							</div> 
							<hr className="MuiDivider-root" style={{ backgroundColor: 'white' }} />
						</div>
						</>
						)}

				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}
