import Bereadcrumb from '../components/Bereadcrumb'
import couponIcon from '../images/ic_apply_coupon.png'
import arrowIcon from '../images/brandArrow.svg'
import shoppingCartIcon from '../images/shopping_cart.svg'
import OffersPriceDetailsIcon from '../images/Offers_price_details.svg'
import OfferIcon from '../images/Offers_icon.svg'
import PriceDetailsIcon from '../images/PriceDetails.svg'
import CartSubtotalIcon from '../images/CartSubtotal.svg'
import Shipping_CostIcon from '../images/Shipping_Cost.svg'
import DiscountIcon from '../images/Discount.svg'
import AmountPayableIcon from '../images/AmountPayable.svg'
import GiftCardIcon from '../images/GiftCard.svg'
import Collapse from 'react-bootstrap/Collapse'
import { useState, useEffect } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import CartProduct from '../components/CartProduct'
import withCart from '../HOCs/withCart'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {useWebsite} from '../context/WebsiteContext'
import Select from 'react-select'
import useDeliveryCharge from '../hooks/useDeliveryCharge'
import { Link, useNavigate } from "react-router-dom";
import { callToast, numberWithSpaces, isEmpty } from "../lib/functions"
import MBNavbar from '../components/MBNavbar'
import MBFooter from '../components/MBFooter'
import useWidth from '../hooks/useWidth'
import CustomerSideBar from "../components/CustomerSideBar";


function Cart() {
	const navigate = useNavigate();
	const width = useWidth()
	
	const {loading, error, deliveryCharges} = useDeliveryCharge();
	const {isLoggedInState, cartState, totalCartQuantity, totalCartAmount, setDeliveryChangeInfo} = useWebsite();
	const [deliveryChargeOption, setDeliveryChargeOption ] = useState([]);
	const [currentDeliveryCharge, setCurrentDeliveryCharge ] = useState(null);
	const [currentDeliveryChargeAmount, setCurrentDeliveryChargeAmount ] = useState(0);




	const [promoCode, setPromoCode] = useState('')
	const handlePromoCode = e => setPromoCode(e.currentTarget.value)
	const [offerVisible, setOfferVisible] = useState(false)
	const toggleOfferVisible = () => setOfferVisible(v => !v)

 

	useEffect(() => { 
		var arr = [];
		for (var i = 0, l1 = deliveryCharges.length; i < l1; i++) {
			var de = {
				value :  deliveryCharges[i].id,
				label :  deliveryCharges[i].name,
				charge :  deliveryCharges[i].charge,
				name :  deliveryCharges[i].name,
				id :  deliveryCharges[i].id
			};

			if(i === 0){
				setCurrentDeliveryCharge(deliveryCharges[i]);
				setCurrentDeliveryChargeAmount(deliveryCharges[i].charge);
				setDeliveryChangeInfo(de) 
			}
			arr[i] = de;
		}
		setDeliveryChargeOption(arr); 

	}, [deliveryCharges])

	const confirmOrder = () => {
		if(totalCartQuantity <= 0){
			callToast('error', "No Product is found"); 
		}else{
			navigate('/shipping');
		}
	}



	return (
		<>
			{width <= 768 ? (
				<>
					<MBNavbar searchButton={false} />
					<div className="font-weight-bold" style={{ paddingBottom: 136 }}>
						{ (isLoggedInState === true) && (
							<>
								<div>
									{cartState.map(data => (
										<div  key={data.id} >
											{(!isEmpty(data.product)) && (
												<CartProduct data={data}  mobile />
											)}
										</div>
									))}
								</div>
								<div className="mt-1 py-2 border-top border-bottom" style={{ backgroundColor: '#FFF0F6' }}>
									<section>
										<div className="container-fluid">
											<div className="d-flex">
												<div className="px-2 pt-1 align-items-center">
													<img
														src={couponIcon}
														className="d-block"
														width={30}
														height={30}
														alt="coupon"
													/>
												</div>
												<div className=" px-2">
													<div className="mt-2">
														<span className="cartAvailableOffers brandColor">
															VIEW ALL OFFERS
														</span>
													</div>
												</div>
												<div className="d-flex justify-content-end flex-grow-1 pt-1">
													<div>
														<img src={arrowIcon} width={15} height={15} alt="next" />
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>

								<div className="mt-2 px-2 bg-white border-top border-bottom ">
									<div className="pt-2 cartFontSubHeadings">
										<span className="cartPriceDetails">Price Details</span>
									</div>
									<div>
										<div className="d-flex">
											<div style={{ width: "100%" }}>
												{ (deliveryChargeOption.length > 0) && (
													<Select options={deliveryChargeOption} 
														defaultValue={deliveryChargeOption[0]} 
														onChange={(delivery) => {
															setCurrentDeliveryCharge(delivery); 
															setCurrentDeliveryChargeAmount(delivery.charge) 
															setDeliveryChangeInfo(delivery) 
													}}/>
												)}
											</div>
										</div>
										<div className="d-flex justify-content-between ">
											<div>
												<div>
													<span className=" text-secondary cartFontSubHeadings">Subtotal</span>
												</div>
											</div>
											<div>
												<div>
													<span className="cartPriceValues">Tk { numberWithSpaces(totalCartAmount) }</span>
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-between ">
											<div>
												<div>
													<span className=" text-secondary cartFontSubHeadings">Shipping</span>
												</div>
											</div>
											<div>
												<div>
													<span className="cartPriceValues">Tk  { numberWithSpaces(currentDeliveryChargeAmount) }</span>
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-between ">
											<div>
												<div>
													<span className=" text-secondary cartFontSubHeadings">Discount</span>
												</div>
											</div>
											<div>
												<div>
													<span className="text-success cartFontSubHeadings">0.00</span>
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-between py-1 border-top">
											<div>
												<div>
													<div className="cartTotal">Amount Payable</div>
												</div>
											</div>
											<div>
												<div>
													<div className="cartTotal">Tk { numberWithSpaces(currentDeliveryChargeAmount + totalCartAmount) }</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cartWishlistBottom " />
								<div className="fixed-bottom text-white mb-5 cartViewCheckBackground ">
									<div className="d-flex justify-content-between px-1 pb-3">
										<div>
											<div className=" pt-1 px-1 cartViewCheckTotal">Tk { numberWithSpaces(currentDeliveryChargeAmount + totalCartAmount) }</div>
											<span className="px-1 cartFont12">View Details</span>
										</div>
										<div className="pt-2">
											<button className="btn mainBgColor px-5 checkout-button">
												<span className="text-dark px-4 cartCouponApplied" onClick={confirmOrder} >CHECKOUT</span>
											</button>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
					<MBFooter />
				</>
			) : (
				<>
					<Navbar />
					<div style={{ overflowX: 'hidden' }} className="pb-3">
						<div>
							<Bereadcrumb pages={[{ path: '/cart', name: 'Cart' }]} />
							{ (isLoggedInState === true) && (
								<div
									className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
									style={{ borderRadius: "20px" }}
								>
									<div className="row container-fluid">
										<div className="col-12 col-lg-2 px-4 ">
											<section className="mt-3">
												<CustomerSideBar />
											</section>
										</div>
										<div className="col-12 col-lg-10 py-3">
											<div className="shadow-lg mt-4 mx-3 bg-white" style={{ borderRadius: '10px' }}>
												<div className="row container-fluid">
													<div className="col-12 col-lg-8 px-4 ">
														<section className="mt-3">
															<div className="d-flex justify-content-between cart_orderSummary">
																<div>
																	<span>
																		<h6>
																			<img
																				src={shoppingCartIcon}
																				width="20px"
																				height="20px"
																				alt="Shopping Cart Icon"
																			/>
																			<span className="px-2"> Order Summary</span>
																		</h6>
																	</span>
																</div>
																<div> 
																	<h6 style={{ textDecoration: 'underline' }}>
																		Cart Total : Tk {  (totalCartAmount) ? numberWithSpaces(totalCartAmount) : 0 }
																	</h6>
																</div>
															</div>
														</section>
														<section className="mt-3 mb-4 child-scroll-hide">
															<div style={{ height: 400 }}>
																{cartState.map(data => (
																	<div key={data.id}>
																		{(!isEmpty(data.product)) && (
																			<CartProduct  data={data}   />
																		)}
																	</div> 
																))}
															</div>
														</section>
													</div>
													<div className="col-12 col-lg-4 py-3">
														<section>
															<div className="d-flex justify-content-between cart_orderSummary">
																<div>
																	<span>
																		<h6>
																			<img
																				src={OffersPriceDetailsIcon}
																				width="20px"
																				height="20px"
																				alt=""
																			/>
																			<span className="px-2">Offers and Price Details</span>
																		</h6>
																	</span>
																</div>
															</div>
														</section>
														<div style={{ background: '#faf9f9', borderRadius: '10px' }} className="p-2 ">
															<section>
																<div className="d-flex">
																	<div>
																		<img
																			src={PriceDetailsIcon}
																			width="20px"
																			height="20px"
																			alt=""
																		/>
																	</div>
																	<div className="px-2 pt-1">
																		<p style={{ fontSize: '13px' }}>
																			<span style={{ textDecoration: 'underline' }}>Price</span>
																			Details
																		</p>
																	</div>
																</div>
															</section>
															<section className="bg-white rounded">
																<div className="container-fluid py-2">
																	{
																		(deliveryChargeOption.length > 0)? (
																			<div className="d-flex mb-2" style={{ fontSize: '13px' }}>
																				<div style={{ width: "15rem" }}> 
																					<Select options={deliveryChargeOption} 
																						defaultValue={deliveryChargeOption[0]} 
																						onChange={(delivery) => {
																							setCurrentDeliveryCharge(delivery); 
																							setCurrentDeliveryChargeAmount(delivery.charge) 
																							setDeliveryChangeInfo(delivery) 
																						}}/>
																				</div>
																			</div>
																		):""
																	}
																	<div className="d-flex mb-2" style={{ fontSize: '13px' }}>
																		<span>
																			<img
																				src={CartSubtotalIcon}
																				width="15px"
																				height="15px"
																				alt=""
																			/>
																		</span>
																		<span className="px-2">Cart Sub Total: </span>
																		<span className="px-5">Tk { numberWithSpaces(totalCartAmount) }</span>
																	</div>
																	
																	<div
																		className="d-flex mb-2"
																		style={{ fontSize: '13px', display: 'inline-block' }}
																	>
																		<span>
																			<img
																				src={Shipping_CostIcon}
																				width="15px"
																				height="15px"
																				alt=""
																			/>
																		</span>
																		<span className="px-2">Shipping Cost: </span>
																		<span className="px-5">Tk  { numberWithSpaces(currentDeliveryChargeAmount) }</span>
																	</div>
																	<div className="d-flex mb-2" style={{ fontSize: '13px' }}>
																		<span>
																			<img
																				src={DiscountIcon}
																				width="15px"
																				height="15px"
																				alt=""
																			/>
																		</span>
																		<span className="px-2">Discount Applied: </span>
																		<span className="px-4">
																			<span className="px-1 text-success">Tk 0.00</span>
																		</span>
																	</div>
																	<div>
																		<div className="d-flex " style={{ fontSize: '13px' }}>
																			<span>
																				<img
																					src={AmountPayableIcon}
																					width="15px"
																					height="15px"
																					alt=""
																				/>
																			</span>
																			<span className="px-2">Amount Payable: </span>
																			<span className="px-4">
																				<span className="px-2 fw-bold">Tk { numberWithSpaces(currentDeliveryChargeAmount + totalCartAmount) }</span>
																			</span>
																		</div>
																		<div style={{ fontSize: '10.5px' }} className="px-4 ">
																			<span>Including Tk { numberWithSpaces(currentDeliveryChargeAmount + totalCartAmount) } in taxes</span>
																		</div>
																	</div>
																</div>
															</section>
															<section className="my-3 py-2">
																<div>
																	<div>
																		<div className="d-flex border " style={{ fontSize: '13px' }}>
																			<div className="px-3 pt-2 cursor">
																				<Link to="/" style={{ color: 'black' }} >
																					&lt;
																					<span
																						className="px-1 "
																						style={{ textDecoration: 'underline' }}
																					>
																						Continue Shopping
																					</span>
																				</Link>
																			</div>
																			<div className="flex-grow-1">
																				<button
																					className="btn btn-dark form-control py-2"
																					style={{ fontSize: '13px' }}
																					onClick={confirmOrder}
																				>
																					CHECKOUT
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</section>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

						</div>
					</div>
					<Footer />
				</>
			)}
		</>
	)
}

export default Cart
