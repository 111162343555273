import CollectionTitle from '../components/CollectionTitle'
import LoadingSpinner from '../components/LoadingSpinner'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useSearchParams } from 'react-router-dom'

import Product from '../components/Product' 
import Bereadcrumb from '../components/Bereadcrumb'
import ProductsHeadline from '../components/ProductsHeadline'
import { useParams, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component'
import useProductList from '../hooks/useProductList'
import { useState, useEffect } from "react";
import axios from "../lib/axiosConfig";
import { Helmet } from "react-helmet";

export default function Search(props) {
	let [searchParams] = useSearchParams();
	let searchProductName = searchParams.get('q');

	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [products, setProducts] = useState([]);
	const [pageProducts, setPageProducts] = useState([]);
	const [totalResult, setTotalResult] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		async function fetchProducts() {
			try {
				
				await axios({
					method: "get",
					url: "search-products",
					params: {
						q: searchProductName,
					}
				})
				.then((response) => {
					setProducts(preProduct => {
						return [...new Set([...preProduct, ...response.data.data])]
					});
					// setProducts(response.data.data);
					setTotalResult(response.data.total_result);
					setHasMore(response.data.has_more);
					setLoading(false);
					setError(false);
				})
				.catch((err) => {
					setProducts([]);
					setTotalResult(0);
					setLoading(false);
					setError(err);
				});
			} catch (error) {
				console.log('Fetch data error');
			}
		}

		fetchProducts();
		return () => {
			setProducts([]);
			setTotalResult(0);
			setError("");
			setLoading(true);
		};
	}, [searchProductName, page]);


	useEffect(() => {
		setPageProducts(prePageProduct => {
			return [...new Set([...prePageProduct, ...products])]
		});
	}, [products]);


	const location = useLocation();

	useEffect(() => {
		setPage(1);
		setPageProducts([]);

		return () => {
			setPageProducts([]);
			setPage(1);
		};
	}, [location]);


	return (
		<>
			<Navbar />
			<div className="px-3">
				<CollectionTitle
					render={() => (
						<div>
							Search Results for <span className="text-decoration-underline"> {searchProductName} </span>
						</div>
					)}
				/>
				<div className="container">
					{pageProducts.length > 0 ? (
						<InfiniteScroll
							dataLength={pageProducts.length}
							hasMore={hasMore}
							next={() => setPage(page + 1)}
							loader={<LoadingSpinner />}
						>
							<div className="d-flex flex-wrap">
								{pageProducts.map((product, pi) => (
									<Product key={pi} product={product} />
								))}
							</div>
						</InfiniteScroll>
					) : (
						<>
							{loading && !error && (<div><LoadingSpinner /></div>)}
						</>
					)}
					{!loading && pageProducts.length === 0 && (<div> No Data found</div>)}
					{loading && error && (<div> There is an error</div>)}
				</div>
			</div>
			<Footer />
		</>
	)
}
