import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import useSliderList from '../hooks/useSliderList'
import Loading from './Loading';
import { AiFillAndroid } from "react-icons/ai";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const responsive = {
	desktop: {
		breakpoint: { max: 5000, min: 768 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
	},
}
 

function Sliders({ sliderData, mobile }) {
 
	 

	const carouselLeftIcon = (
	<button aria-label="Go to previous slide" type="button" class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left">
		<AiFillAndroid/>
	</button>
	);

	return (
		<div>
			{sliderData.length > 0 && (
				<div
					className={`slide-carousel ${mobile ? 'slide-carousel-mobile' : 'slide-carousel-desktop'}`}
					style={mobile ? { marginTop: '1em', marginBottom: '1.5em' } : {}}
				>
					<Carousel
						swipeable={true}
						draggable={true}
						responsive={responsive}
						showDots={true}
						infinite={true}
						autoPlay={true}
						autoPlaySpeed={3000}
						keyBoardControl
						transitionDuration={500}
						removeArrowOnDeviceType={['mobile']}
						renderDotsOutside={!mobile}
						containerClass="carousel-standerd"
						// customLeftArrow={carouselLeftIcon}
					>
						{sliderData.map(slider => (
							<div key={Math.random()}>
								{/* <Link to="/product"> */}
									<LazyLoadImage
										className="d-block  cursor"
										width="100%"
										src={
											mobile
												? slider.mobile_image_path
												: slider.image_path
										}
										alt={slider.title}
										title={slider.title}
										style={{
											height: mobile ? 'auto' : '24.08vw',
											display: 'block',
										}}
									/>
								{/* </Link> */}
							</div>
						))}
					</Carousel>
				</div>
			)} 
		</div>
	)
}

export default Sliders;
