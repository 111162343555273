import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";

const useHomePageContent = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [homePageContents, setHomePageContents] = useState([]);

	useEffect(() => {
		async function fetchSections() {
			try {
				await axios
					.get("home-page-content")
					.then((response) => {
						setHomePageContents(response.data.data);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setHomePageContents([]);
						setLoading(false);
						setError(err);
					});
			} catch (error) {
				setLoading(false);
				setError("Connection Error");
			}
		} 
		fetchSections();

		return () => {
			setHomePageContents([]);
			setError("");
			setLoading(true);
		};
	}, []);

	return {
		loading,
		error,
		homePageContents,
	};
};

export default useHomePageContent;
