import './styles/globals.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from './pages/Home'
import Product from './pages/Product'
import CollectionProduct from './pages/CollectionProduct'
import OfferProducts from './pages/OfferProducts'
import CollectionProducts from './pages/CollectionProducts'

import Products from './pages/products'
import MBProducts from './pages/MBProducts'
import PageContent from './pages/PageContent'
import Terms from './pages/Terms'
import Returns from './pages/Returns'
import FAQs from './pages/FAQs'
import AboutUs from './pages/AboutUs'
import Page404 from './pages/Page404'
import Offers from './pages/Offers'
import OffersVendor from './pages/OffersVendor'
import MBOffersVendor from './pages/MBOffersVendor'
import ComboPage from './components/ComboPage'
import Categories from './pages/Categories'
import MBCart from './pages/MBCart'
import Cart from './pages/Cart'
import Wishlist from './pages/Wishlist'
import Shipping from './pages/Shipping'
import Search from './pages/Search'
import MBSearch from './pages/MBSearch'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import useRestoredScroll from './hooks/useRestoredScroll'
import { QueryClient, QueryClientProvider } from "react-query";
import { WebsiteProvider } from './context/WebsiteContext'
import Registration from './pages/Registration'
import Login from './pages/Login'
import PrivateRoute from './components/PrivateRoute'
import PrivateOutlet from './components/PrivateOutlet'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Profile from './pages/Profile'
import ProfileUpdate from './pages/ProfileUpdate'
import ChangerPassword from './pages/ChangerPassword'
import DeliveryAddress from './pages/DeliveryAddress'
import DeliveryAddressAdd from './pages/DeliveryAddressAdd'
import VideoCheck from './pages/VideoCheck'

import Order from './pages/Order'
import OrderDetail from './pages/OrderDetail'

export default function App() {
	const RestoredScrollbar = useRestoredScroll;
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<WebsiteProvider>
				<BrowserRouter basename="/">
					<RestoredScrollbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route
							path="/products"
							element={<ComboPage mobile={<MBProducts />} desktop={<Products />} />}
						/>

						<Route path='/product' element={<Product/>}>
							<Route path=':productSlug' element={<Product/>}></Route>
						</Route>


						<Route path="/categories" element={<Categories />} />

						<Route path="/terms" element={<PageContent name='terms-condition' title='TERMS &amp; CONDITIONS' />} />
						<Route path="/returns" element={<PageContent name='returns-refunds' title='RETURNS &amp; REFUNDS' />} />
						<Route path="/about-us" element={<PageContent name='about-us' title='About &amp; Us' />} />
						<Route path="/search" element={<ComboPage mobile={<MBSearch />} desktop={<Search />} />} />
						<Route path="/faqs" element={<FAQs />} />
						<Route
							path="/offers-vendor"
							element={<ComboPage mobile={<MBOffersVendor />} desktop={<OffersVendor />} />}
						/>
						<Route path="/offers" element={<Offers />} />

						<Route path='/offer' element={<OfferProducts/>}>
							<Route path=':offerSlug' element={<OfferProducts/>}></Route>
						</Route>

						<Route path='/collections' element={<CollectionProduct/>}>
							<Route path=':collection' element={<CollectionProduct/>}></Route>
						</Route>

						<Route path='/collection' element={<CollectionProducts/>}>
							<Route path=':collectionSlug' element={<CollectionProducts/>}></Route>
						</Route>

						<Route path=':categorySlug' element={<ComboPage mobile={<MBProducts />} desktop={<Products />} />}>
							<Route path=':subcategorySlug' element={<Products/>}></Route>
						</Route>
						<Route path="/video-check" element={<VideoCheck />} />
						 
						
						{/* <Route path="/:categorySlug/:subcategorySlug?" element={<Products />} />
						<Route path="/:categorySlug" element={<Products />} /> */}



						<Route path="/registration" element={<Registration />} />

						 
						<Route path="/login" element={<Login />} />

						{/* <Route path="/cart" element={<ComboPage mobile={<MBCart />} desktop={<Cart />} />} /> */}
						{
							/**
							 * After Login Route
							 */
						}
						<Route path="/cart" element={ <PrivateRoute> <Cart /> </PrivateRoute> } />
						<Route path="/wishlist" element={ <PrivateRoute> <Wishlist /> </PrivateRoute> } />
						<Route path="/shipping" element={ <PrivateRoute> <Shipping /> </PrivateRoute> } />
						<Route path="/profile" element={ <PrivateRoute> <Profile /> </PrivateRoute> } />
						<Route path="/profile/update" element={ <PrivateRoute> <ProfileUpdate /> </PrivateRoute> } />
						<Route path="/profile/change-password" element={ <PrivateRoute> <ChangerPassword /> </PrivateRoute> } />
						<Route path="/delivery-address" element={ <PrivateRoute> <DeliveryAddress /> </PrivateRoute> } />
						<Route path="/delivery-address/add" element={ <PrivateRoute> <DeliveryAddressAdd /> </PrivateRoute> } />
						<Route path="/orders" element={ <PrivateRoute> <Order /> </PrivateRoute> } />
						<Route path="/orders/:orderId" element={ <PrivateRoute> <OrderDetail /> </PrivateRoute> } />
						{
							/**
							 * After Login Route
							 */
						}

						

						{/* <Route path="/*" element={<PrivateOutlet />}>
							<Route path="cart" element={<ComboPage mobile={<MBCart />} desktop={<Cart />} />} />
						</Route> */}

						<Route path="/*" element={<ComboPage mobile={<Page404 mobile />} desktop={<Page404 />} />} />
						

					</Routes>
				</BrowserRouter>


				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover 
				/>
			</WebsiteProvider>
		</QueryClientProvider>
	)
}

// reactjs/nextjs developer: akashrahman.me@gmail.com
