import BestSeller from "../components/BestSeller";
import Section from "../components/Section";

import Sliders from "../components/Sliders";
import HotDeals from "../components/HotDeals";
import MBCollection from "../components/MBCollection";
import QuizSection from "../components/QuizSection";
import BeautyTips from "../components/BeautyTips";
import BeautyBlog from "../components/BeautyBlog";
import Explore from "../components/Explore";
import SectionFooter from "../components/SectionFooter";
import MBStories from "../components/MBStories";
import MBBestSeller from "../components/MBBestSeller";
import MBSection from "../components/MBSection";
import VideoStreaming from "../components/VideoStreaming";
import MBNavbar from "../components/MBNavbar";
import MBFooter from "../components/MBFooter";
import useWidth from "../hooks/useWidth";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TopPicks from "../components/TopPicks";
import ThisOrThatSections from "../components/ThisOrThatSections";
import Collection from "../components/Collection";
import useSectionList from "../hooks/useSectionList";
import useHomePageContent from "../hooks/useHomePageContent";
import useCollectionList from "../hooks/useCollectionList";
import useThisOrThatSectionList from "../hooks/useThisOrThatSectionList";
import { Helmet } from "react-helmet";
import { useWebsite } from "../context/WebsiteContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Home() {
  const { websiteInfo } = useWebsite();

  const width = useWidth();
  const { loading: homePageContentLoading, homePageContents } =
    useHomePageContent();
  // const { loading: sectionLoading, error, sections } = useSectionList()
  // const { loading: collectionLoading, errors, collections } = useCollectionList()
  // const { loading: thisOrThatSectionLoading, error:thisOrThatSectionError, thisOrThatSections } = useThisOrThatSectionList()

  const handleSection = (content) => {
    if (content.type === "Slider") {
      return width <= 768 ? (
        <Sliders sliderData={content.sliders} mobile />
      ) : (
        <Sliders sliderData={content.sliders} />
      );
    } else if (content.type === "Section") {
      return width <= 768 ? (
        <MBSection
          datam={content.section.product_sections}
          headline={content.section.name}
          descrive={content.section.name}
        />
      ) : (
        <Section
          datam={content.section.product_sections}
          headline={content.section.name}
        />
      );
    } else if (content.type === "Video Streaming") {
      return content.videoStreamings.length > 0 ? (
        <VideoStreaming videoStreamingData={content.videoStreamings} />
      ) : null;
    } else if (content.type === "Collection Category") {
      return width <= 768 ? (
        <MBCollection
          collectionData={content.collection.collections}
          headline={content.collection.name}
        />
      ) : (
        <Collection
          collectionData={content.collection.collections}
          headline={content.collection.name}
        />
      );
    }
    // else if(content.type === 'This Or That Section'){
    // 	return <ThisOrThatSections datam={content.thisOrThatSections} headline="THIS OR THAT" />;
    // }
    else if (content.type === "Blog") {
      return content.blogs.length > 0 ? (
        <BeautyBlog blogsData={content.blogs} />
      ) : null;
    }
  };

  return (
    <>
      {websiteInfo && (
        <>
          <Helmet>
            <title>Home Page </title>
            <meta name="description" content={websiteInfo.meta_description} />
            <meta name="keywords" content={websiteInfo.meta_keywords} />
            <meta name="author" content={websiteInfo.meta_author} />
          </Helmet>
        </>
      )}

      {width <= 768 ? (
        <>
          <MBNavbar searchIcon={false} />
          <main style={{ paddingBottom: "4em" }}>
            <MBStories />
            <div className="container-fluid">
              {homePageContents.length > 0 &&
                homePageContents.map((content, pi) => (
                  <React.Fragment key={pi}>
                    {handleSection(content)}
                  </React.Fragment>
                ))}
            </div>
            <SectionFooter />
          </main>
          <MBFooter />
        </>
      ) : (
        <>
          <Navbar LazyLoadImage={LazyLoadImage} />
          {homePageContentLoading && (
            <div>
              <LoadingSpinner />
            </div>
          )}
          <div className="text-center titleFontHeading">
            {homePageContents.length > 0 &&
              homePageContents.map((content, pi) => (
                <React.Fragment key={pi}>
                  {handleSection(content)}
                </React.Fragment>
              ))}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
