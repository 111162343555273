import Bereadcrumb from '../components/Bereadcrumb'
import couponIcon from '../images/ic_apply_coupon.png'
import arrowIcon from '../images/brandArrow.svg'
import shoppingCartIcon from '../images/shopping_cart.svg'
import OffersPriceDetailsIcon from '../images/Offers_price_details.svg'
import OfferIcon from '../images/Offers_icon.svg'
import PriceDetailsIcon from '../images/PriceDetails.svg'
import CartSubtotalIcon from '../images/CartSubtotal.svg'
import Shipping_CostIcon from '../images/Shipping_Cost.svg'
import DiscountIcon from '../images/Discount.svg'
import AmountPayableIcon from '../images/AmountPayable.svg'
import GiftCardIcon from '../images/GiftCard.svg'
import Collapse from 'react-bootstrap/Collapse'
import { useState, useEffect } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import WishlistProduct from '../components/WishlistProduct'

import withCart from '../HOCs/withCart'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {useWebsite} from '../context/WebsiteContext'
import {numberWithSpaces} from '../lib/functions';
import Select from 'react-select'
import useDeliveryCharge from '../hooks/useDeliveryCharge'
import { Link, useNavigate } from "react-router-dom";
import { callToast,isEmpty } from "../lib/functions"
import useWidth from '../hooks/useWidth'
import MBNavbar from '../components/MBNavbar'
import MBFooter from '../components/MBFooter'
import CustomerSideBar from "../components/CustomerSideBar";

function Wishlist() {
	const navigate = useNavigate();
	const width = useWidth()

	const {isLoggedInState, cartState, totalCartQuantity, totalCartAmount, setDeliveryChangeInfo, wishlist : wishlistProduct} = useWebsite();

	const [promoCode, setPromoCode] = useState('')
	const handlePromoCode = e => setPromoCode(e.currentTarget.value)
	const [offerVisible, setOfferVisible] = useState(false)
	const toggleOfferVisible = () => setOfferVisible(v => !v)
 
 


	return (
		<>
		{width <= 768 ? (
			<>
			<MBNavbar searchButton={false} />
					<div className="font-weight-bold" style={{ paddingBottom: 136 }}>
						{ (isLoggedInState === true) && (
							<>
								<div>
									{wishlistProduct.map(data => (
										<>
											{(!isEmpty(data.product)) && (
												<WishlistProduct key={data.id} data={data}  mobile />
											)}
										</>
									))}
								</div>  
							</>
						)}
					</div>
					<MBFooter />
			</>
		) : (
			<>
				<Navbar />
				<div style={{ overflowX: 'hidden' }} className="pb-3">
					<div>
						<Bereadcrumb pages={[{ path: '/wishlist', name: 'Wishlist' }]} />
						{ (isLoggedInState === true) && (
							<div
							className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
							style={{ borderRadius: "20px" }}
						>
							<div className="row container-fluid">
								<div className="col-12 col-lg-2 px-4 ">
									<section className="mt-3">
										<CustomerSideBar />
									</section>
								</div>
								<div className="col-12 col-lg-10 py-3">
									
							<div className="shadow-lg mt-4 mx-3 bg-white" style={{ borderRadius: '20px' }}>
								<div className="row container-fluid">
									<div className="col-12 col-lg-12 px-4 ">
										<section className="mt-3">
											<div className="d-flex justify-content-between cart_orderSummary">
												<div>
													<span>
														<h6>
															<img
																src={shoppingCartIcon}
																width="20px"
																height="20px"
																alt="Shopping Cart Icon"
															/>
															<span className="px-2"> Wishlist Summary</span>
														</h6>
													</span>
												</div> 
											</div>
										</section>
										<section className="mt-3 mb-4 child-scroll-hide">
											<div style={{ height: 400 }}>
												{wishlistProduct.map(data => (
													<>
														{(!isEmpty(data.product)) && (
															<WishlistProduct key={data.id} data={data}  mobile />
														)}
													</>
												))}
											</div>
										</section>
									</div> 
								</div>
							</div>
 
								</div>
							</div>
						</div>
						
						)}

					</div>
				</div>
				<Footer />
			</>

		)}
		</>
	)
}

export default Wishlist
