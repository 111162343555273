import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import useWidth from '../hooks/useWidth'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 768 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
}
 
export default function BeautyBlog({blogsData}) {
	const width = useWidth();
	return (
		<div>
			<div className="my-3">
				{width >= 768 ? (
					<h2>BD Beauty Glamorous BLOG</h2>
				) : (
					<h5 className="text-center">BD Beauty Glamorous BLOG</h5>
				)}
			</div>
			<Carousel
				responsive={responsive}
				arrows={false}
				showDots={width <= 768}
				infinite={true}
				autoPlay={true}
			>
				{blogsData.map(blog => (
					<div className="px-md-3" key={Math.random()}>
						<Link to={'/'}> 
							<LazyLoadImage
								src={ blog.image_path }
								alt={blog.title}
								title={blog.title} 
								className="d-block img-fluid cursor"
								style={{
									width: '100%',
									height: '14rem',
								}}
							/>
							{ blog.title } 
						</Link>
					</div>
				))}
			</Carousel>
		</div>
	)
}
