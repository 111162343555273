import { createContext, useContext, useState, useEffect, useReducer} from "react";
import axios, {setCsrfToken} from "../lib/axiosConfig";
import  {isLoggedInCheck, setValueLocalStorage,getValueLocalStorage} from "../lib/functions"; 

const WebsiteContext = createContext();


const CartReducer = (state,action) => {
		
	switch(action.type){
		case "ADD_TO_CART":
			if(state.length){
				var check = 0;
				var cart = state.map((c) => {
					if(c.id === action.payload.id){
						check = 1;
						c.cartQty = c.cartQty + 1
					}
					return c;
				});
				if(check){
					return cart;
				}
			} 
			return [...state, { ...action.payload, cartQty: 1 }]; 

		case "REMOVE_FROM_CART":
			return {
				...state,
				cart: state.cart.filter((c) => c.id !== action.payload.id),
			};

		case "CHANGE_CART_QTY":
			return {
				...state,
				cart: state.cart.filter((c) =>
					c.id === action.payload.id
						? (c.cartQty = action.payload.qty)
						: c.cartQty
				),
			};

		case "SET_CART":
			return action.payload; 


		default:
			return state;
	}
}

export function useWebsite() {
	return useContext(WebsiteContext);
}

export function WebsiteProvider({ children }) {
	
	const [cartState, cartDispatch] = useReducer(CartReducer, [])
	const [wishlist, setWishlist] = useState([])
	const [wishlistCount, setWishlistCount] = useState(null)
	const [totalCartQuantity, setTotalCartQuantity] = useState(null);
	const [totalCartAmount, setTotalCartAmount] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isLoggedInState, setIsLoggedInState] = useState(false);
	const [websiteInfo, setWebsiteInfo] = useState({});
	const [userInfo, setUserInfo] = useState({});
	const [deliveryChangeInfo, setDeliveryChangeInfo] = useState({});
	const [productFilter, setProductFilter] = useState([]);
	const [filters, setFilters] = useState([]);
	const [categories, setCategories] = useState([]);

    useEffect(() => {
		/**
		 * Fetch Website Information
		 */
        async function fetchWebsiteInfo() {
			try {
				setCsrfToken();

				await axios
					.get("info")
					.then((response) => {
						setLoading(false);
						if(response.data && response.data.application){
							setWebsiteInfo(response.data.application);
							localStorage.setItem("websiteInfo", JSON.stringify(response.data.application));
						}

						if(response.data && response.data.filters){
							setFilters(response.data.filters);
							localStorage.setItem("filters", JSON.stringify(response.data.filters));
						}

						if(response.data && response.data.categories){
							setCategories(response.data.categories);
						}

					})
					.catch((err) => {
						setWebsiteInfo([]);
						setFilters([]);
						setCategories([]);
						setLoading(false);
						localStorage.setItem("websiteInfo", JSON.stringify([]));
						localStorage.setItem("filters", JSON.stringify([]));
					});
			}
			catch (error) {
				setWebsiteInfo([]);
				setFilters([]);
				setCategories([]);
				setLoading(false);
				localStorage.setItem("websiteInfo", JSON.stringify([]));
				localStorage.setItem("filters", JSON.stringify([]));
			}
		}

	 
		fetchWebsiteInfo();

	 
		/**
		 * Fetch User Information
		 */
		async function loadUser() {
			try {
				var loggedToken = getValueLocalStorage();
				await axios({
					method: "post",
					url: "user",
					headers: { Authorization: `Bearer ${loggedToken}` }
				})
				.then((response) => {
					localStorage.setItem("isLoggedIn", true);
					setUserInfo(response.data.user);

					setTotalCartQuantity(response.data.cart.cartProductQuantity);
					setTotalCartAmount(response.data.cart.cartProductAmount);
					setWishlist(response.data.wishlist.wishlistProducts);
					setWishlistCount(response.data.wishlist.wishlistCount);

					cartDispatch({
						type: "SET_CART",
						payload: response.data.cart.cartProducts,
					});

					setIsLoggedInState(true);
				})
				.catch((err) => {
					localStorage.setItem("isLoggedIn", false);
					localStorage.setItem("loggedToken", '');
				});
			}
			catch (error) {
				localStorage.setItem("isLoggedIn", false);
				localStorage.setItem("loggedToken", '');
			}
		}

		/**
		* Check User Information in Local store
		*/
		if(isLoggedInCheck()){
			loadUser();
		}
		else{
			localStorage.setItem("isLoggedIn", false);
			localStorage.setItem("loggedToken", '');
			setIsLoggedInState(false);
			setUserInfo({});
		}

	}, []);
 
 

	const value = {
		websiteInfo,
		cartState,
		cartDispatch,
		isLoggedInState,
		setIsLoggedInState,
		userInfo,
		setUserInfo,
		totalCartQuantity, 
		setTotalCartQuantity,
		totalCartAmount,
		setTotalCartAmount,
		deliveryChangeInfo, 
		setDeliveryChangeInfo,
		wishlist,
		setWishlist,
		wishlistCount, 
		setWishlistCount,
		productFilter,
		setProductFilter,
		filters,
		categories
	};

	return (
		<WebsiteContext.Provider value={value}>
			{!loading && children}
		</WebsiteContext.Provider>
	);
}
