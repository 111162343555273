import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MBNavbar from '../components/MBNavbar'
import MBFooter from '../components/MBFooter'
import useWidth from '../hooks/useWidth'
import { useWebsite } from '../context/WebsiteContext'

 

export default function Categories() {
	const width = useWidth()
	const {categories} = useWebsite();

	return (
		<>
			{width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}

			<div style={{ paddingBottom: 70 }}>
				{(categories.length > 0) && categories.map((category,index) => (
					<div key={index}>
						<Link to={`/${category.slug}`}>
							<div className="pt-1 px-1 position-relative text-white">
								<img src={category.image_path} width="100%" className="rounded-3" alt='' />
								<div
									className="fs-4 position-absolute categoriesText"
									style={{ zIndex: '1', top: 0 }}
								>
									<div className="d-flex justify-content-start px-2 text-uppercase">
										{category.name}
									</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</div>
			{width >= 768 ? <Footer /> : <MBFooter />}
		</>
	)
}
