import Bereadcrumb from "../components/Bereadcrumb";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link } from "react-router-dom";

function DeliveryAddress() {
	const { userInfo } = useWebsite(); 

	return (
		<>
			<Navbar />
			<div style={{ overflowX: "hidden" }} className="pb-3">
				<div>
					<Bereadcrumb pages={[{ path: "/cart", name: "Cart" }]} />
					<div
						className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
						style={{ borderRadius: "20px" }}
					>
						<div className="row container-fluid">
							<div className="col-12 col-lg-2 px-4 ">
								<section className="mt-3">
									<CustomerSideBar />
								</section>
							</div>
							<div className="col-12 col-lg-10 py-3">
								<section>
									<div className="d-flex justify-content-between cart_orderSummary">
										<div>
											<span>
												<h6>
													<span className="px-2">
														Deliver Address
													</span>
												</h6>
											</span>
										</div>
									</div>
								</section>
								<div
									style={{
										background: "#faf9f9",
										borderRadius: "10px",
									}}
									className="p-2 "
								>
									{(userInfo && userInfo.customer_delivery_addresses) && (
										<section className="px-2 my-2 pt-2">
											<div className="row"> 
												{ userInfo.customer_delivery_addresses.map((delivery_address) => (
													<div className="col-lg-6 mt-3" key={delivery_address.id}>
														<div className="card">
															<h5 className="card-header">{ delivery_address.full_name }</h5>
															<div className="card-body">
																<h5 className="card-title">{ delivery_address.contact_number }</h5>
																<p className="card-text">{ delivery_address.address }</p>
															</div>
														</div>
													</div>
												))} 
											</div>
										</section>
									)}

									<section className="px-2 my-2 pt-2">
										<Link
											className="btn btn-primary mx-3"
											to="/delivery-address/add"
										>
											Add Delivery Address
										</Link> 
									</section>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default DeliveryAddress;
