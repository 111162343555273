import { Link } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import { useState } from 'react'
import { numberWithSpaces, callToast,getValueLocalStorage } from './../lib/functions'
import axios from "../lib/axiosConfig"
import { useWebsite } from '../context/WebsiteContext' 
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function WishlistProduct({ data, mobile }) {
	const {setWishlist,setWishlistCount, isLoggedInState, setTotalCartQuantity, setTotalCartAmount} = useWebsite();
	 
	const [item, setItem] = useState(data.quantity)
	const addItem = () => setItem(item => item + 1)
	const removeItem = () => setItem(item => item - 1)
 

	const removeProductQuantity = async (type) => {
		if(window.confirm('Are you sure to remove it?')){
			var loggedToken = getValueLocalStorage(); 
			try {
				await axios({
					method: 'post',
					url: 'remove-from-wishlist',
					headers: { Authorization: `Bearer ${loggedToken}` },
					data: {
						wishlist_id : data.id,
					}
				})
				.then((response) => {
					callToast('success',response.data.message); 
					setWishlist(response.data.wishlist.wishlistProducts);
					setWishlistCount(response.data.wishlist.wishlistCount);
				})
				.catch((err) => {
					callToast('warn','Product from Wishlist Delete into Error'); 
				});
			} catch (error) {
				callToast('warn','Product from Wishlist Delete into Error');	
			} 
		}
	}

	return mobile ? (
		<div className="mb-2">
			<div>
				<div className="d-flex bg-white">
					<Link to={`/product/${data.product.slug}`}>
						<div className="px-2 py-2 d-flex align-items-center" style={{ width: '80px' }}>
							<LazyLoadImage
								src={data.product.image_path}
								className="cursor img-fluid d-block"
								width="100%"
								height="100%"
								alt={data.title}
							/>
						</div>
					</Link>
					<div className="flex-grow-1 font-weight-bold pt-1 position-relative">
						<div className="cartProdFlex">
							<div className="mt-0 py-1">
								<div className="d-flex justify-content-between">
									<Link to={`/product/${data.product.slug}`} style={{ color: 'black' }}>
										<div className="pt-1">
											<div
												className="d-inline-block text-truncate"
												style={{
													maxWidth: '18rem',
													fontSize: '14px',
													fontWeight: 550,
												}}
											>
												{data.product.name}
											</div>
										</div>
									</Link>
									<button
										type="button"
										className="bg-transparent border-0 m-0 p-0"
										onClick={removeProductQuantity} id={data.id} 
									>
										<MdDelete fontSize={24} color="rgb(108, 117, 125)" />
									</button> 
								</div>
							</div>  
							<div>
								<Link to={`/product/${data.product.slug}`} style={{ color: 'black' }}>
									<div className="text-muted" style={{ fontSize: '12px' }}>
										{data.product.name}
									</div>
								</Link>
							</div>
							<div>
								<Link to={`/product/${data.product.slug}`} style={{ color: 'black' }}>
									<div
										className="d-flex text-secondary justify-content-between "
										style={{ fontSize: '12px' }}
									>
										<div className="d-flex justify-content-start">
											<div className={'px-2'}>TK. {numberWithSpaces(data.product.sale_rate)}</div>
										</div>
									</div>
								</Link>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="py-2">
			<div className="row">
				<div className="col-8">
					<div className="row"> 
						<div className="col-3">
							<div>
								<Link to={`/product/${data.product.slug}`}>
									<img
										src={data.product.image_path}
										width={90}
										height={90}
										className="rounded shadow-sm img-fluid d-block h-90"
										alt=""
									/>
								</Link>
							</div>
						</div>
						<div className="col-9 mt-3 cart_productTitle">
							<div className="d-flex flex-column justify-content-between">
								<div className=" ">
									<Link
										to={`/product/${data.product.slug}`}
										style={{
											textDecoration: 'none',
											color: 'rgb(87, 85, 85)',
										}}
									>
										{data.product.name}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-4 ">
					<div className="d-flex justify-content-around mt-4">
						<div onClick={removeProductQuantity} id={data.id} className="cursor-pointer">
							<MdDelete fontSize={16} color="#6c757d" />
						</div> 
					</div>
				</div>
			</div>
		</div>
	)
}
