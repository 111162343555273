import Bereadcrumb from "../components/Bereadcrumb";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Profile({ datam, handleRemoveItem }) {
	const { userInfo } = useWebsite(); 

	return (
		<>
			<Navbar />
			<div style={{ overflowX: "hidden" }} className="pb-3">
				<div>
					<Bereadcrumb pages={[{ path: "/cart", name: "Cart" }]} />
					<div
						className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
						style={{ borderRadius: "20px" }}
					>
						<div className="row container-fluid">
							<div className="col-12 col-lg-2 px-4 ">
								<section className="mt-3">
									<CustomerSideBar />
								</section>
							</div>
							<div className="col-12 col-lg-10 py-3">
								<section>
									<div className="d-flex justify-content-between cart_orderSummary">
										<div>
											<span>
												<h6>
													<span className="px-2">
														Profile
													</span>
												</h6>
											</span>
										</div>
									</div>
								</section>
								<div
									style={{
										background: "#faf9f9",
										borderRadius: "10px",
									}}
									className="p-2 "
								>
									{userInfo && (
										<section className="px-2 my-2 pt-2">
											{ (userInfo.image)  && (
											<div className="row py-2">
												<div className="col-lg-3">
													<img
														alt={userInfo.name}
														src={userInfo.image_path}
														className="d-block img-fluid cursor"
														width="100%"
														height="auto"
														title={userInfo.name}
														style={{
															width: '20rem',
															height: '10rem',
															borderRadius: '1%',
														}}
													/>
												</div>
												<div className="col-lg-9"></div>
											</div>
											)}

											<div className="row py-2 ">
												<div className="col-lg-3">
													Name
												</div>
												<div className="col-lg-9">
													{userInfo.name}
												</div>
											</div>
											<div className="row py-2">
												<div className="col-lg-3">
													Email
												</div>
												<div className="col-lg-9">
													{userInfo.email}
												</div>
											</div>
											<div className="row py-2">
												<div className="col-lg-3">
													Contact Number
												</div>
												<div className="col-lg-9">
													{userInfo.contact_number}
												</div>
											</div>
											<div className="row py-2">
												<div className="col-lg-3">
													Address
												</div>
												<div className="col-lg-9">
													{userInfo.address}
												</div>
											</div>
										</section>
									)}
									<section className="px-2 my-2 pt-2">
										<Link
											className="btn btn-primary mx-3"
											to="/profile/update"
										>
											Update Profile
										</Link>
										<Link
											className="btn btn-primary mx-3"
											to="/profile/change-password"
										>
											Change Password
										</Link>
										<Link
											className="btn btn-primary mx-3"
											to="/profile/update"
										>
											Change Profile
										</Link>
									</section>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Profile;
